<template>
  <div :class="`${prefixCls}-introduction`" id="aochenIntro">
    <Separate title="公司简介" desc="COMPANY PROFILE" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div
        :class="`${prefixCls}-introduction-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
      <img src="@/assets/image/home/jinao.png" alt="">
      <div class="company-introduction-desc">
        <div class="desc">
          <p>舟山金鳌海洋集团有限公司（以下简称金鳌集团），由央企上市公司易华录参股的山东易海陆圆（原山东易华录），联合舟山普陀交通旅游投资发展集团有限公司、舟山六横海钓产业发展有限公司、舟山市普陀区渔港建设管理有限公司于2023年5月共同成立。公司积极创新，以小型船艇智慧化管理运营、数字海钓公园综合运营服务、海洋文旅运营及赛事服务、海洋文化、海洋大数据运营等为发展目标；以数据为底座、文化为灵魂、创新产业发展，构建一条鱼产业链，打造全国文旅休闲渔业的舟山典范。
          </p>
          <p>目前，金鳌集团作为全国最大的船艇管理服务公司，下设小型船艇管理子公司，所纳管525艘海钓船艇，进一步完善明确管控要素，建立健全一系列标准措施，创新小型船艇管理新体制，实行“人船”双向闭环管理。目前，整个海钓船艇安全运行逐渐形成规范，船艇、钓客管理和海钓产业数字化等皆走在全国前列，已进一步拓宽渔民转变渔业路子，促进休闲渔业的持续健康发展。
          </p>
          <p>同时，金鳌集团以向海图强、发展蓝色经济为初心，加强海洋文化的传播，强化全民海洋文化自信，发展海洋文化产业，结合数字化改革契机发展海钓产业。同步开展配套监管、海洋文旅运营服务，推动当地海洋文化和海洋生态的可持续性融合发展，打造以休闲渔业为主体的“文化圣地、赛事高地”为知名度和影响力的城市品牌。</p>
<!--          <p>舟山金鳌海洋集团有限公司（以下简称金鳌集团），由央企上市公司易华录控股的山东易华录，联合舟山普陀交通投资发展集团有限公司、舟山六横海钓产业发展有限公司、舟山市普陀区渔港建设管理有限公司共同成立。</p>-->
<!--          <p>公司积极创新，以小型船艇安全管理运营、海钓公园综合运营服务、海钓文旅及赛事、海钓文化、海钓智造、海钓学院、海钓大数据交易等为发展目标；以数据为底座、文化为灵魂、创新产业发展，构建一条鱼产业链，打造全国文旅休闲渔业的舟山典范。-->
<!--          </p>-->
<!--          <p>金鳌集团以向海图强、发展蓝色经济为初心，加强海洋文化传播，强化全民海洋文化自信，发展海洋文化产业，结合数字化改革契机发展海钓产业。金鳌集团下设小型船艇管理子公司、海钓公园管理及赛事文创运营子公司，开展配套监管、运营服务，推动当地海洋文化和海洋生态的可持续性融合发展，打造以海钓为主体的“文化圣地、赛事高地”为知名度和影响力的城市品牌。-->
<!--          </p>-->
<!--          <p>金鳌小型船艇管理公司以安全监管为首要任务，负责对海上小型船艇进行集中纳管，统一作业区域范围，健全细化小型船艇安全管理制度，执行海洋生态环境保护及海洋渔业资源修复工作，创新建立海上小型船艇管理服务，助力海上小型船艇母港基地建设，完善船艇维修、保养、加油等服务，规范海钓产业市场行为，打造海上小型船艇公司化管理的“普陀样板”。-->
<!--          </p>-->
<!--          <p>金鳌海钓公园管理及赛事文创运营公司负责普陀海钓公园日常管理和运营维护，开展“浙里钓”应用配套运营服务，负责普陀“海钓文化圣地”和“海钓赛事高地”建设，形成“四重三榜二中心一学院”现代休闲渔业产业体系格局，打造现代海洋产业新高地、渔文旅融合发展“金名片”，形成围绕“两山理论”海上实践为目标的海洋生态文明产业链。-->
<!--          </p>-->
        </div>
        <div class="func">
          <div class="card-box">
            <div class="img-card" v-for="item in imgList" :key="item.id" @click="handleGo2(item)">
              <img :src="item.icon" alt="" />
              <span class="labelCn">{{ item.labelCn }}</span>
              <span class="labelEn">{{ item.labelEn }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
export default {
  name: 'HomeComponentsIntroduction',
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      funcList: [
        {
          id: 1,
          labelCn: '企业文化',
          labelEn: 'Enterprise Culture'
        },
        {
          id: 2,
          labelCn: '社会责任',
          labelEn: 'Social Responsibility'
        }, {
          id: 3,
          labelCn: '企业荣誉',
          labelEn: 'Enterprise Honor'
        }
      ],
      imgList: [
        {
          id: 1,
          labelCn: '企业文化',
          labelEn: 'Enterprise Culture',
          href: 'aochenCulture',
          icon: require('@/assets/image/home/culture.png'),
          path: '/aochen/culture'
        },
        {
          id: 2,
          labelCn: '社会责任',
          labelEn: 'Social Responsibility',
          href: 'aochenSocial',
          icon: require('@/assets/image/home/social.png'),
          path: '/aochen/social'
        },
        {
          id: 3,
          labelCn: '资质荣誉',
          labelEn: 'Qualification Honor',
          href: 'aochenHonors',
          icon: require('@/assets/image/home/honor.png'),
          path: '/aochen/honors'
        }
      ]
    }
  },
  mixins: [WowMixin],
  components: {
    Separate
  },
  methods: {
    handleGo2 ({ path, href }) {
      this.$router.push({ path })
      setTimeout(() => {
        document.querySelector(`#${href}`).scrollIntoView({
          behavior: 'smooth'
        })
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -home-introduction;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: $content-width;
  padding-bottom: 50px;
  overflow: hidden;
  &-content{
    width: 100%;
    //height: 00px;
    word-spacing: 14px;
    font-size: 14px;
    line-height: 2;
    text-align: left;
    color: #707B7C;
    img {
      width: 100%;
    }
    .company-introduction-desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      //height: 100%;
      .desc {
        font, p, span {
          font-family: arial !important;
          font-size: 16px !important;
          color: #191818 !important;
          line-height: 40px !important;
          font-weight: lighter !important;
          text-indent: 2em;
        }
      }
      .card-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .img-card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            width: 40px;
            height: 46px;
          }
          .labelCn {
            color: #666;
            font-size: 14px;
            margin: 3px 0;
          }
          .labelEn {
            color: #999;
            font-size: 14px;
            word-spacing: 0px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
